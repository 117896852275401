#contact-admin-container {
    position: relative;
    bottom: 27px;
    width: 100%;
    background-color: #292b2cc2;
    padding: 30px;
    border-color: white;
    border-style: dashed;
    border-width: 0 2px 2px 2px;
}

#contact-textarea-wrap {
    margin-top: 3em;
    padding-top: 50%;
    position: relative;
}
#contact-admin-container > form {
    text-align: center;
}
#contact-admin-container > form button {
    margin-top: 1.6em;
    border: none;
    padding: 1rem;
    width: 40%;
    font-size: 1.2em;
    border-radius: 0.2rem;
    transition: 0.4s;
    background-color: #c7c7c7;
}
#contact-admin-container > form button:hover {
    color: rgb(211, 155, 52);
    background-color: white;
}