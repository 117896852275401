a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.text-white-Hoverable {
  color: white !important;
}
.text-white-Hoverable:hover {
  color: rgb(211, 155, 52) !important;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

#navbar-search-module {
  display: flex;
  align-items: center;
  margin-right: 5px;
  padding: 5px 0;
}
#navbar-search-module > input {
  flex: 1;
  padding: 5px;
  margin-right: 10px;
  border-radius: 2px;
  border: none;
}
#navbar-search-module > button {
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  background-color: rgba(255, 255, 255, 0);

  transition: 100ms background-color ease-in-out;
}
#navbar-search-module > button:hover {
  background-color: rgb(70, 70, 70)
}

/* Search */

#recipe-search-exit {
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: pointer;
  color:white;

  transition: 100ms color;
}
#recipe-search-exit:hover {
  color: rgb(182, 182, 182);
}

@keyframes searchSlider-down {
  from {max-height: 0; visibility: hidden;}
  to {max-height: 600px; visibility: visible;}
}
@keyframes searchSlider-up {
  from {max-height: 600px; visibility: visible;}
  to {max-height: 0; visibility: hidden;}
}
@keyframes A-animC-down {
  from {opacity: 0; visibility: hidden;}
  to {opacity: 1; visibility: visible;}
}
@keyframes A-animC-up {
  from {opacity: 1; visibility: visible;}
  to {opacity: 0; visibility: hidden;}
}

#recipe-search-animC {
  height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
}
.animC-down {
  animation-name: A-animC-down;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
  animation-delay: 600ms;
}
.animC-up {
  animation-name: A-animC-up;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
}

#recipe-search-container {
  position: relative;
  bottom: 17px;
  width: 100%;
  visibility: hidden;
  max-height: 0;
  height: 100%;
  background-color: #292b2c;
  border-color: white;
  border-style: dashed;
  border-width: 0 0 2px 0;
  border-top: 1px solid white;

}
.search-container-down {
  animation-name: searchSlider-down;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
}
.search-container-up {
  animation-name: searchSlider-up;
  animation-duration: 600ms;
  animation-fill-mode: forwards;
}


.r-search-recipe-module:first-child {
  margin-top: 2em;
}
.r-search-recipe-module {
  margin-bottom: 3em;
}
.r-search-recipe-module > a {
  text-decoration: none;
  color: white;
  transition: 150ms color;
}
.r-search-recipe-module > a:hover, .r-search-recipe-module > a:focus {
  text-decoration: none;
  color: rgb(211, 155, 52);
}

/*
.recipe-search-image {
  position: relative;
  padding-top: 50%;
  max-width: 300px;
  width: 100%;
}
.recipe-search-image > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
*/

#recipe-search-pages {
  margin-top: 3em;
  width: 100%;
  text-align: center;
}
#recipe-search-flex {
  justify-content: center;
  display: flex;
}
#recipe-search-flex > .recipe-sub-page {
  cursor: pointer;
  color: white;
  transition: 140ms color;
}
#recipe-search-flex > .recipe-sub-page:hover {
  color:rgb(255, 214, 139);
}
#recipe-search-flex > .recipe-sub-page:not(:first-child) {
  margin-left: 0.4em;
}
#recipe-search-flex > .recipe-sub-page:first-child::before {
  color:rgb(211, 155, 52);
  content: '-\0020';
}
#recipe-search-flex > .recipe-sub-page:last-child:after {
  color:rgb(211, 155, 52);
  content: '\0020-';
}
/* -- END -- */

#admin-messages-alert {
  user-select: none;
  cursor: pointer;
  position: fixed;
  z-index: 101;
  top: 50%;
  left: 0;

  padding: 15px;
  background-color: #292b2c;
  border: 2px solid white;
  border-radius: 9px;

  transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);

  transition: 100ms background-color;
}
#admin-messages-alert:hover {
  background-color: #404242;
}
#admin-messages-close {
  top:1em;
  right: 1em;
  position: absolute;
  cursor: pointer;
  color: white;
  transition: 100ms color;
}
#admin-messages-close:hover {
  color:rgb(255, 0, 0);
}
#admin-message-deleteAll {
  top:1em;
  left: 1em;
  position: absolute;
  cursor: pointer;
  color: white;
  transition: 100ms color;
}
#admin-message-deleteAll:hover {
  color:rgb(255, 0, 0);
}
#admin-messages-container {
  margin-top: 5em;
}
#admin-messages-all {
  top: 0;
  left: 0;
  background-color: #292b2c;
  overflow-y: scroll;
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
}
.admin-message-wrap {
  margin: 0 auto;
  padding: 2em;
  margin-top: 1.5em;
  border: 2px solid white;
  max-width: 700px;
  width: 100%;
}
.admin-message-wrap:last-child {
  margin-bottom: 3em;
}
.admin-message-message {
  margin: 1em auto 0 auto;
  border: 1px dashed white;
  padding: 10px;
}
