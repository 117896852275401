::-webkit-scrollbar {
    width: 10px;
}
  
  /* Track */
::-webkit-scrollbar-track {
    background: none; 
}
   
  /* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
}
  
  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#user-panel-wrapper {
    position: relative;
    bottom: 27px;
    color: white
}
#user-panel-wrapper > #user-panel-info {
    text-align: center;
    background-color: #292b2c;
    padding: 20px;
    border-style: dashed;
    border-color: white;
}

.user-panel-container {
    margin: 40px 0 80px 0;
    min-height: 800px;
}
.user-panel-container > .row > .col-lg-4 {
    border: 3px solid white;
    border-radius: 6px;
    padding: 25px;
    background-color: #292b2c;
}
.user-panel-container > .row > .col-lg-4 > #user-panel-user-ingredients-container {
    height: 100%;
    width: 100%;
    position: relative;
}
#user-panel-user-ingredients-container > #user-panel-uingredients-scroll {
    overflow-y: scroll;
    max-height: 690px;
    height: 100%;
    border: 2px solid rgb(70, 70, 70);
    border-radius: 8px;
    padding: 20px;
}

/* Uingredient */

#user-panel-uingredients-scroll > .user-panel-uingredients-scroll-element {
    position: relative;

    text-align: center;
    padding: 15px 0;
    height: 78px;
    border-radius: 10px;
    width: 100%;
    border: 2px solid white;
    color:white;
    user-select: none;
    transition: 100ms;
}
#user-panel-uingredients-scroll > .user-panel-uingredients-scroll-element:hover {
    background-color: rgb(70, 70, 70);
}

.user-uingredient-edit {
    color: rgb(126, 126, 126);
    transition: 100ms color ease-in-out;
    margin: 0 auto;
    width: min-content;

    transition: 200ms;

    visibility: hidden;
    opacity: 0;
    -moz-opacity: 0;
}

.user-uingredient {
    transform: translateY(50%);
    -o-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -webkit-transform: translateY(50%);
}

/* DISABLING HOVER EFFECT HERE */

.scroll-element-hoverableEdit > .user-uingredient{
    transition: 100ms transform;
}
.scroll-element-hoverableEdit:hover  > .user-uingredient {
    transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
}
/* !! -- END -- !! */

.user-uingredient-edit:hover {
    color: white;
}
.user-panel-uingredients-scroll-element:hover > .user-uingredient-edit,
.user-panel-uingredients-scroll-element:hover > .user-uingredient-delete {
    cursor: pointer;
    visibility: visible;
    opacity: 1;
    -moz-opacity: 1;
}

.user-uingredient-delete {
    top: 0;
    right: 0;
    position: absolute;
    height: 100%;
    width: 20%;
    border-style: dashed;
    border-color: white;
    border-width: 0 0 0 2px;

    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    opacity: 0;
    -moz-opacity: 0;
    visibility: hidden;

    transition: 200ms background-color;
}
.user-uingredient-delete > div {
    display: grid;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: white;

    transition: 100ms color ease-in-out;
}
.user-uingredient-delete:hover {
    background-color: rgb(59, 0, 0);
}
.user-uingredient-delete:hover > div {
    color:rgb(255, 104, 104)
}

/* -- END -- */

#user-panel-uingredients-scroll > .user-panel-uingredients-scroll-element:not(:first-child) {
    margin-top: 20px;
}
#user-panel-user-ingredients-container > #user-panel-uingredients-inputs {
    margin-top: 1.5em;
    width: 100%;
    display: flex;
}
#user-panel-uingredients-inputs > #user-panel-allIngr {
    padding: 4px;
    flex: 2;
    margin-right: 1em;
    width: 100%;
}
#user-panel-uingredients-inputs > #user-panel-allIngr-Add {
    flex: 1;
    text-align: center;
    width: 100%;
    cursor: pointer;
    user-select: none;
    padding: 4px;
    color: rgb(211, 155, 52);
    background-color: #292b2c;
    border: 2px solid white;
    border-radius: 5px;

    transition: 100ms background-color ease-in-out;
}
#user-panel-uingredients-inputs > #user-panel-allIngr-Add:hover {
    background-color: rgb(70, 70, 70);
}


/*RIGHT SIDE*/
.user-panel-container > .row > .col-lg-8 {
    display: flex;
    flex-direction: column;
}

.user-panel-container > .row > .col-lg-8 > .row > #user-panel-button {
    color: rgb(211, 155, 52);
    width: 100%;
    padding: 25px;
    border: 2px solid white;
    background-color: #292b2c;
    user-select: none;
    text-align: center;
    border-radius: 10px;
    transition: 100ms background-color ease-in;
}
.user-panel-container > .row > .col-lg-8 > .row > #user-panel-button:hover {
    cursor: pointer;
    background-color: rgb(70, 70, 70);
}
#user-panel-main-labels {
    margin-top: 15px;
    display: flex;
}
#user-panel-main-labels > label {
    flex: 1;
    height: 78px;
    text-align: center;
    max-width: 400px;
    border-radius: 10px;
    transition: 0.4s;
    border: 2px solid white;
    background-color: #292b2c;
    color: rgb(211, 155, 52);
    user-select: none;
}
#user-panel-main-labels > label > .centered {
    position: relative;

    top: 50%;
    transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}
.user-panel-container > .row > .col-lg-8 > #user-panel-main-labels > label:hover {
    cursor: pointer;
}
#user-panel-main-labels > label:not(:last-child) {
    margin-right: 20px;
}
.user-panel-container > .row > .col-lg-8 > #user-panel-main-labels > input:checked + label {
    background-color: rgb(70, 70, 70);
}

.user-panel-container> .row > .col-lg-8 > .row {
    display: block;
}
/* RECIPE LIST */
.user-panel-container > .row > .col-lg-8 > #user-panel-view-result {
    position: relative;
    overflow-y: scroll;
    background-color: #292b2caf;
    margin-top: 15px;
    min-width: 100%;
    height: 620px;
    padding: 25px;
    border: 3px solid white;
    border-radius: 6px;
}
.user-panel-loading-signature {
    position: absolute;
    top: 50%;
    left: 50%; 
    transform: translate(-50%,-50%);
    width: auto;
}
#user-panel-view-result > a {
    display: block;
    text-decoration: none;
    color: black;
}
#user-panel-view-result > a:not(:last-child) {
    margin-bottom: 15px;
}
.user-panel-recipe-list-element > .user-list-element-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    border: 2px solid black;
    border-radius: 5px;
}
.list-element-img {
    border-width: 0 1px 0 0;
    border-style: dashed;
    border-color: rgb(170, 170, 170);
    padding-right: 5px;
}
.list-element-img > img {
    max-width: 300px;
    width: 100%;
    height: 100%;
    aspect-ratio: 2;
    object-fit: cover;
    border-width: 0 2px 0 0;
    border-style: solid;
    border-color: black;
    border-radius: 2px;
}
.list-element-text {
    position: relative;
    width: 100%;
    text-align: center;
    background-color: white;
    transition: 100ms background-color ease-in;
}
.list-element-text > .reserve-recipe-add {
    cursor: pointer;
    font-size: 1.1em;
    font-weight: 500;
    color:rgb(211, 155, 52);
    position: absolute;
    z-index: 5;
    background-color: white;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);

    padding: 2px 10px;
    border-style: solid;
    border-width: 0 2px 2px 2px;
    border-color: rgb(211, 155, 52);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    transition: 200ms;
    opacity: 0;
    -moz-opacity: 0;
    visibility: hidden;
}
.list-element-text:hover > .reserve-recipe-add {
    opacity: 1;
    -moz-opacity: 1;
    visibility: visible;
}
.reserve-recipe-add:hover {
    background-color: rgb(211, 155, 52);
    color: white;
}
.list-element-text > .reserve-recipe-name {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
a:hover > .user-panel-recipe-list-element > .user-list-element-container > .list-element-text {
    background-color: rgb(236, 236, 236)
}
/* ERROR MESSAGE */

#ErrorMessage-container {
    user-select: none;
    text-align: center;
    position: fixed;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);

    padding: 25px 0;
    background-color: #292b2c;
    transition: 100ms background-color ease-in;
    border: 2px solid white;
    border-radius: 10px;

    cursor: pointer;
}
#ErrorMessage-container:hover {
    background-color: rgb(70, 70, 70);
}
#ErrorMessage {
    color: white;
    margin: auto;
    width: 70%;
    border-width: 0 0 2px 0;
    border-style: solid;
    border-color: white;
}

/* Ingredient Edit */

.user-uingredient-edit-form {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 20px;
    visibility: hidden;
}
.user-uingredient-edit-form > form {
    display: flex;
}
form > .uingredient-bad {
    cursor: pointer;
    border: 2px solid white;
    width: 35px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;

    transition: 200ms background-color, color ease-in-out;
}
.user-uingredient-edit-form > form > input {
    width: 100%;
    padding-left: 5px;
    outline: none;
    border-style: solid;
    border-width: 2px 0 2px 0;
    border-color: white;
    background-color: #0000005b;
    color: white;
}
form > .uingredient-ok {
    cursor: pointer;
    border: 2px solid white;
    width: 35px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    transition: 200ms background-color, color ease-in-out;
}
form > .uingredient-bad:hover {
    background-color: white;
    color: red;
}
form > .uingredient-ok:hover {
    background-color: white;
    color: green;
}

/* -- END -- */

#user-reserve-recipes {
    padding: 10px;
    overflow-y: scroll;
    height: 620px;
    border: 2px solid green;
}
.reserve-recipe {
    color: black;
}
.missing-ingredients > div:not(:last-child)::after {
    content: ',\00A0';
}

#pantryEdit-absolute-wrapper {
    position: absolute;
    z-index: 6;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #292b2caf;
}
#pantryEdit-absolute {
    position: relative;
    width: 100%;
    height: 100%;
}
#pantryEdit-absolute > .centered {
    background-color:#292b2c;
    border: 2px solid white;
    border-radius: 8px;
    padding: 30px;

    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
}
#pantryEdit-absolute > .centered > div > h2 {
    margin-bottom: 1em;
}
.centered > .pantryEdit-ingredient {
    padding: 10px 20px;
    margin: 1em auto 0 auto;
    border: 2px solid rgb(211, 155, 52);
    border-radius: 8px;
    text-align: center;
    width: fit-content;
    user-select: none;
}
.pantryEdit-buttons {
    cursor: pointer;
    margin-top: 2em;
    display: flex;
    flex-direction: row;
}
.pantryEdit-buttons > .pantryEdit-button {
    flex: 1;
    padding: 15px 20px;
    text-align: center;
}
.pantryEdit-buttons > .btn-bckgrd-red {
    margin-right: 1em;
    background-color: red;
    transition: 200ms;
}
.pantryEdit-buttons > .btn-bckgrd-red:hover {
    background-color: rgb(255, 108, 108);
}
.pantryEdit-buttons > .btn-bckgrd-green {
    background-color: green;
    transition: 200ms;
}
.pantryEdit-buttons > .btn-bckgrd-green:hover {
    background-color: rgb(0, 160, 0);
}

#user-panel-favorites, #user-panel-history {
    background-color: #292b2c;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 2px solid white;
    padding: 2em;
    cursor: pointer;
}
#user-panel-favorites > h5 > span, #user-panel-history > h5 > span {
    color: white;
    transition: 100ms color;
}
#user-panel-favorites:hover > h5 > span, #user-panel-history:hover > h5 > span {
    color: gray;
}
.user-panel-fav-hist-element {
    margin-top: 1em;
    padding: 1em;
}
.user-panel-fav-hist-element:last-child {
    margin-bottom: 1em;
}
.user-panel-fav-hist-element > a {
    text-decoration: none;
    color: white;

    transition: 100ms color;
}
.user-panel-fav-hist-element > a:hover {
    color:rgb(211, 155, 52);
}

@keyframes user-panel-down {
    from {height: 0; visibility: hidden;}
    to {height: 300px; visibility: visible;}
}
@keyframes user-panel-up {
    from {height: 300px; visibility: visible;}
    to {height: 0; visibility: hidden;}
}

#user-panel-fav-container, #user-panel-hist-container {
    position: relative;
    background-color:#292b2cd5;
    border-width: 0 2px 2px 2px;
    border-style: dashed;
    border-color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    overflow-y: scroll;
    height: 0;

    visibility: hidden;
}
.user-panel-sliders-down {
    animation-name: user-panel-down;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
.user-panel-sliders-up {
    animation-name: user-panel-up;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}
.fav-hist-container-centered {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
}