@media (max-width: 768px){
    .arec-row-ingspec {
        text-align: left;
    }
}

.recipe-container-add {
    background-color: #292b2caf;
    color: white;
    margin: 0 auto 70px auto;
    width: 100%;
    overflow-x: hidden;
    padding: 50px;
    border: 3px solid white;
    border-radius: 10px;
}

.row > .arec-row-input-text {
    text-align: right;
}

.row > .arec-row-input-text > input,
.recipe-child-list > .row > .col-8 > input,
.recipe-child-list > .row > .col-4 > input,
.recipe-child-list-spice > .row > .col-8 > input {
    width: 100%;
    padding: 10px;
    border: none;
    outline: 2px solid rgb(199, 199, 199);
    border-radius: 10px;
    outline-offset: -0.2em;
    background-color: #292b2c;
    color: white;
}

.arec-row-ingredients-spices {
    padding-bottom: 20px;
}

.arec-row-ingredients-spices > .col-sm-8 > .recipe-child-list:not(:first-child),
.arec-row-ingredients-spices > .col-sm-8 > .recipe-child-list-spice:not(:first-child) {
    margin-top: 5px;
}

.arec-row-input > input[type=button] {
    border: 2px solid white;
    background-color: #292b2caf;
    color: white;
    border-radius: 15px;
    padding: 5px 10px;
    transition: 0.2s border-color, color ease-in-out;
}

.arec-row-input > input[type=button]:hover {
    border-color: rgb(211, 155, 52);
    color: rgb(211, 155, 52);
}

.arec-row-ingspec, .arec-row-text {
    text-align: right;
}


.recipe-child-add-file {
    text-align: left;
    padding-top: 30px;
    padding-bottom: 10px;
}

.recipe-child-add-textarea {
    padding-top: 10px;
    padding-bottom: 10px;
}

.recipe-child-add-textarea > textarea {
    background-color: #292b2c;
    color: white;
}

div.recipe-container-add > form > button {
    margin-top: 0.5em;
    border: none;
    padding: 1rem;
    width: 100%;
    font-size: 1.2em;
    border-radius: 0.2rem;
    transition: 0.4s;
    background-color: #c7c7c7;
}
div.recipe-container-add > form > button:hover {
    background-color: white;
    color: rgb(211, 155, 52);
}
