/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
body {
  color: white;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  background-position: top;
}

@media (max-width: 375px) {
  .user-list-element-container > .list-element-text > .reserve-recipe-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

/* Home.js and media queries */
@media (max-width: 575px) {
  .register > form > input, 
  .register > form > button, 
  .register > form > .div-button-r-l {
    min-width: 50%;
    width: auto;
  }
  .reg-container {
    bottom: 27px;
  }
  #main-home-page {
    position: relative;
    bottom: 27px;
  }
  #user-panel-wrapper > #user-panel-info {
    border-width: 0 0 2px 0;
  }
  body {
    background-color: #777777;
  }
  .cookieContainer {
    border-top-right-radius: 0;
    border-width: 2px 0 0 0;
  }
  #contact-admin-container, #Faq-info-container {
    border-width: 0 0 2px 0;
  }
  #main-recipe-background > .container {
    padding-left: 0!important;
    padding-right: 0!important;
  }
  #main-home-container-wrapper {
    border-width: 2px 0 2px 0;
    border-style: solid;
    border-color: white;
    border-radius: 0;
  }
  #main-banner > img {
    border-radius: 0;
  }
  .register {
    border-width: 0 0 2px 0;
    border-style: solid;
    border-color: white;
    border-radius: 0;
  }
  .main-home-container-2 {
    padding: 2em 0 1em 0;
    border-width: 0 0 2px 0;
    border-style: dashed;
    border-color: white;
    background-color: #292b2c;
  }
  .recipe-container-add {
    padding: 10px;
    border-radius: 0;
    border-width: 1px 0 1px 0;
  }
}

@media (min-width: 576px) {
  .register > form > input, 
  .register > form > button, 
  .register > form > .div-button-r-l {
    width: 85%;
  }
  body {
    background-image: url('bckgrd/pexels-malidate-van-784633.jpg');
  }
  #user-panel-wrapper > #user-panel-info {
    border-width: 0 2px 2px 2px;
  }
  .cookieContainer {
    border-top-right-radius: 15px;
    border-width: 2px 2px 0 0;
  }
  #main-home-container-wrapper {
    border-width: 2px 2px 2px 2px;
    border-style: solid;
    border-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 5px 5px 20px #292b2c;
  }
  #main-banner > img {
    border-radius: 10px;
  }
  .register {
    border: 2px solid white;
    border-radius: 10px;
    box-shadow: 5px 5px 20px #292b2c;
  }
  .main-home-container-2 {
    padding: 4em 0 3em 0;
    border-width: 0 2px 2px 2px;
    border-style: dashed;
    border-color: white;
    background-color: #292b2c;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 60px;
    box-shadow: 5px 5px 20px #292b2c;
  }
}


@media (max-width: 767px) {

  /* Home.js */
  #main-banner {
    padding-bottom: 40%;
  }
  .main-home-container-1 > .row > .col-sm-4 {
    margin-bottom: 25px;
  }
  /* -- END -- */

  
  .arec-row-text {
    text-align: center;
  }
  .arec-row-ingredients-spices > .col-sm-4 > .row > .arec-row-input {
    margin-top: 5px;
  }

  /* Register.js */
  .register {
    max-width: 100%;
  }
  /* -- END -- */

  .user-panel-container > .row > .col-lg-8 > #user-panel-view-result {
    height: 400px;
  }
  /* Left Side Padding */
  .user-panel-container > .row > .col-lg-4 {
    padding: 10px;
  }
  /* Left Side Scroll */
  .user-panel-container > .row > .col-lg-4 > #user-panel-user-ingredients-container {
    width: 50%;
  }
  #user-panel-user-ingredients-container > #user-panel-uingredients-scroll {
    height: 300px;
  }
  #user-panel-uingredients-scroll > .user-panel-uingredients-scroll-element {
    text-align: left;
    padding-top: 5px;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-right: 20%;
    height: 58px;
  }
  .user-panel-uingredients-scroll-element > .user-uingredient {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
  }
  #user-panel-user-ingredients-container > #user-panel-uingredients-inputs {
    position: absolute;
    bottom: 50%;
    left: calc(100% + 5px);
    display: block;
    transform: translateY(50%);
    -o-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -webkit-transform: translateY(50%);

    text-align: center;
  }
  #user-panel-uingredients-inputs > #user-panel-allIngr, #user-panel-uingredients-inputs > #user-panel-allIngr-Add {
    margin: 0.5em auto;
    flex: none;
  }
  #user-panel-uingredients-inputs > #user-panel-allIngr {
    width: 70%;
  }
  #user-panel-uingredients-inputs > #user-panel-allIngr-Add {
    width: min-content;
    padding: 4px 10px;
  }
  .user-uingredient-delete {
    opacity: 1;
    -moz-opacity: 1;
    visibility: visible;
    background-color: rgb(59, 0, 0);
  }
  .user-uingredient-edit-form, .user-uingredient-edit {
    display: none;
  }
  .scroll-element-hoverableEdit:hover  > .user-uingredient {
    transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%)
  }
  .user-uingredient {
    transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    -moz-transform: translateY(0);
    -webkit-transform: translateY(0);
  }
  /* -- END -- */
  #user-panel-main-labels {
    text-align: center;
  }
  #user-panel-main-labels > label {
    flex:none;
    width: 50%;
    outline: 2px solid white;
    outline-offset: -0.27em;
    border: 20px solid #ffffff00;
  }
  #user-panel-main-labels > label:not(:last-child) {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 1em;
  }
  /* -- END -- */

  /* AddRecipe.js */
  .arec-row-ingspec {
    text-align: left;
  }
  .recipe-container-add {
    padding: 10px;
    border-width: 1px 1px 1px 1px;
  }
  .arec-row-input-text {
    margin-top: 10px;
  }
  .arec-row-ingredients-spices > .col-sm-4 {
    padding-bottom: 10px;
  }
  /* -- END -- */

}
@media (max-width: 991px) {
  body {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
  }

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  #user-panel-fav-hist > .fav-hist > .col-lg-6:first-child {
    margin-bottom: 1em;
  }
}
@media (min-width:768px) and (max-width: 991px) {
    /* Left Side Padding */
    .user-panel-container > .row > .col-lg-4 {
      padding: 10px;
    }
    .user-panel-container > .row > .col-lg-8 > #user-panel-view-result {
      height: 450px;
    }
    /* Left Side Scroll */
    .user-panel-container > .row > .col-lg-4 > #user-panel-user-ingredients-container {
      width: 50%;
    }
    #user-panel-user-ingredients-container > #user-panel-uingredients-scroll {
      height: 400px;
    }
    #user-panel-uingredients-scroll > .user-panel-uingredients-scroll-element {
      text-align: left;
      padding-top: 5px;
      padding-left: 10px;
      padding-bottom: 5px;
      padding-right: 20%;
      height: 58px;
    }
    .user-panel-uingredients-scroll-element > .user-uingredient {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
    }
    #user-panel-user-ingredients-container > #user-panel-uingredients-inputs {
      position: absolute;
      bottom: 50%;
      left: calc(100% + 5px);
      display: block;
      transform: translateY(50%);
      -o-transform: translateY(50%);
      -ms-transform: translateY(50%);
      -moz-transform: translateY(50%);
      -webkit-transform: translateY(50%);
  
      text-align: center;
    }
    #user-panel-uingredients-inputs > #user-panel-allIngr, #user-panel-uingredients-inputs > #user-panel-allIngr-Add {
      margin: 0.5em auto;
      flex: none;
    }
    #user-panel-uingredients-inputs > #user-panel-allIngr {
      width: 70%;
    }
    #user-panel-uingredients-inputs > #user-panel-allIngr-Add {
      width: min-content;
      padding: 4px 10px;
    }
    .user-uingredient-delete {
      opacity: 1;
      -moz-opacity: 1;
      visibility: visible;
      background-color: rgb(59, 0, 0);
    }
    .user-uingredient-edit-form, .user-uingredient-edit {
      display: none;
    }
    .scroll-element-hoverableEdit:hover  > .user-uingredient {
      transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -webkit-transform: translateY(-50%)
    }
    .user-uingredient {
      transform: translateY(0);
      -o-transform: translateY(0);
      -ms-transform: translateY(0);
      -moz-transform: translateY(0);
      -webkit-transform: translateY(0);
    }
    /* -- END -- */
}


.cookieContainer {
  position: fixed;
  bottom: 0;
  background-color: #292b2c;
  border-style: solid;
  border-color: white;
  z-index: 1000;
  padding: 2em 3em;
}
.row > #agree, .row > #disagree {
  border: 1px dashed white;
  border-radius: 1em;
  margin: auto;
  cursor: pointer;
  transition: 0.4s;
  text-align: center;
}
.row > #agree:hover {
  background-color: #484b4c;
}
.row > #disagree:hover {
  background-color: #36393a;
}

/* Loading signature */

.RMasterloader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid rgb(211, 155, 52);
  width: 60px;
  height: 60px;
  -webkit-animation: RM-load-spin 1s linear infinite; /* Safari */
  animation: RM-load-spin 1s linear infinite;
}

@-webkit-keyframes RM-load-spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes RM-load-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@-o-keyframes RM-load-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@-moz-keyframes RM-load-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* -- END -- */

#main-banner-wrapper {
  margin-bottom: 1.5rem;
  padding-bottom: 2.5rem;
  border-width: 0 0 2px 0;
  border-style: dashed;
  border-color: white;
}
#main-banner {
  padding-top: 50%;
}
#main-banner > img 
{
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
#main-banner {
  width: 100%;
  position: relative;
}
#main-banner > #main-banner-text {
  position: absolute;
  background-color: #292b2cbd;
  border-radius: 10px;
  padding: 15px 10px 8px 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

#main-home-page {
  text-align: center;
  margin-bottom: 20px;
}

#main-home-page > .main-home-container-1 {
  margin-top: 1.5rem;
  padding: 30px;
  border: 2px solid white;
  border-radius: 5px;
  background-color: #292b2c;
}

.main-home-container-1 > .row > div div:not(:last-child) {
  margin-bottom: 0.6em;
}
.main-home-container-1 > .row > .col-sm-8 {
  text-align: left;
}

/* Container - 2 */

#main-account-register {
  text-decoration: none;
  color: white;
  padding: 15px;
  border: 2px solid white;
  background-color: #292b2c93;
  border-radius: 15px;
  width: auto;
  transition: 100ms background-color, border-color, color ease-in-out;
}

#main-account-register:hover {
  background-color: #292b2c;
  color: rgb(211, 155, 52);
  border-color: rgb(211, 155, 52);
}

/* -- END -- */

/* Container - 3 */

#main-home-container-wrapper {
  background-color: #292b2c;
  padding: 0 2.5em;
}

.main-home-container-3 {
  margin-top: 2.5em;
  padding: 20px;
}

.main-home-container-3 h4 {
  font-weight: normal;
  margin-bottom: 20px;
}

.row > .col-md-4 > figure {
  padding-top: 50%;
  position: relative;
}

.row > .col-md-4 > figcaption {
  color: white;
  transition: 100ms color ease-in-out;
}
.row > .col-md-4 > figure:hover + figcaption {
  color:rgb(211, 155, 52)
}

.main-home-container-3 > .row > .col-md-4 > figcaption {
  margin: 0 10px;
  margin-bottom: 1.5em;
}

.row > .col-md-4 > figure > a > img {
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border: 0px solid rgba(255, 255, 255, 0);

  transition: 60ms border ease-in-out;
}
.row > .col-md-4 > figure > a > img:hover {
  border-width: 5px 0 5px 0;
}