#Faq-info-container {
    position: relative;
    bottom: 27px;
    width: 100%;
    background-color: #292b2cc2;
    padding: 30px;
    border-color: white;
    border-style: dashed;
    border-width: 0 2px 2px 2px;
}
#Faq-info-container hr {
    margin: auto;
    width: 20%;
}
#Faq-info-container h3 {
    color: rgb(211, 155, 52);
}
#Faq-info-container h3:not(:first-child) {
    margin-top: 1.5em;
}