
.recipe-container {
    position: relative;
    bottom: 27px;
    background-color: #292b2c;
    margin: 0 auto 70px auto;
    width: 100%;
    overflow-x: hidden;
    padding: 50px;

    border-style: dashed;
    border-width: 0 2px 2px 2px;
    border-color: white;
}

.recipe > #views-container {
    position: absolute;
    top: -1.5em;
}
.recipe {
    position: relative;
}
#views-container > #views, #views-container > .views-icon {
    display: inline-block;
}
#views-container > #views {
    margin-left: 7px;
}
.recipe-container > #votes-container {
    position: absolute;
    top: 0;
    right: 15px;
    padding: 15px 0;
    font-size: 1.2em;
    text-align: center;
}
#votes-container > #votes-up {
    color: rgb(0, 110, 0);
    transition: 100ms color;
    font-size: 1.5em;
}
#votes-container > #votes {
    min-width: 3em;
    width: max-content;
}
#votes-container > #votes-up:hover {
    color: rgb(0, 255, 0);
}
#votes-container > #votes-down {
    color: rgb(129, 0, 0);
    transition: 100ms color;
    font-size: 1.5em;
}
#votes-container > #votes-down:hover {
    color: rgb(255, 0, 0);
}
.recipe-child:not(:first-child) {
    margin-top: 10px;
    margin-bottom: 10px;
}
.recipe-description {
    text-align: justify;
}
.recipe-title {
    font-size: 30px;
}
.recipe-type {
    font-size: 20px;
}
.recipe-image, .recipe-source {
    text-align: center;
}
.recipe-ingredients > div > a {
    text-decoration: none;
    color: black;
}

.recipe-ingredient {
    user-select: none;
    color: white;
    border: 3px solid #292b2c;
    padding: 5px 10px;
    margin-top: 3px;
    margin-bottom: 3px;
    border-radius: 15px;
    width: auto;
    transition: 0.4s;
    outline: 2px solid white;
    outline-offset: -0.25em;
}
.recipe-ingredient:hover {
    background-color: white;
    color: black;
    cursor: pointer;
    user-select: none;
}

.recipe-spice:not(:last-child)::after {
    content: ",\00A0";
}
.recipe-spices {
    padding-bottom: 10px;
}
.recipe-spices > div > p {
    margin-top: 1em;
    text-align: left;
    font-style: italic;
}
#rec-image {
    border-radius: 15px;
}
.recipe-source > a {
    transition: 0.2s;
    text-decoration: none;
    color:rgb(156, 102, 0)
}
.recipe-source > a:hover {
    color:rgb(211, 155, 52)
}
