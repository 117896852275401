#main-recipe-master-container {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100vh;
    height: 100%;
}
#main-recipe-master-container > #main-recipe-background {
    padding-top: 10px;
    margin-bottom: 25px;
}
footer {
    margin-top: auto;
    background-color: white;
    padding: 12px 0;
    width: 100%;
    font-size: 14px;
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: rgb(230, 230, 230);
    box-shadow: 0 0 30px rgb(230, 230, 230);
}
footer > div {
    text-align: center;
}
.footer-c > .row > .col-3 > a {
    display: block;
    width: fit-content;
    margin: auto;
    text-decoration: none;
    color: white;
    transition: 100ms color ease-in-out;
}
.footer-c > .row > .col-3 > a:hover {
    color: rgb(211, 155, 52);
}
.footer-d > .row > .col-3 a {
    text-decoration: none;
    color: white;
    transition: 100ms color ease-in-out;
}
.footer-d > .row > .col-3 a:hover {
    color:rgb(211, 155, 52)
}