#reg-loading--l {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(167, 167, 167, 0.349);

    -webkit-animation: reg-loading--l-a 150ms linear; /* Safari */
    animation: reg-loading--l-a 150ms linear;
}

@-webkit-keyframes reg-loading--l-a {
    0% { background-color: rgba(255, 255, 255, 0) }
    100% { background-color: rgba(167, 167, 167, 0.349) }
  }
  @keyframes reg-loading--l-a {
    0% { background-color: rgba(255, 255, 255, 0) }
    100% { background-color: rgba(167, 167, 167, 0.349) }
  }
  @-o-keyframes reg-loading--l-a {
    0% { background-color: rgba(255, 255, 255, 0) }
    100% { background-color: rgba(167, 167, 167, 0.349) }
  }
  @-moz-keyframes reg-loading--l-a {
    0% { background-color: rgba(255, 255, 255, 0) }
    100% { background-color: rgba(167, 167, 167, 0.349) }
  }


.reg-container {
    position: relative;
    width: 100%;
    /*margin-top: 25px;*/
    margin-bottom: 25px;
}

.register {
    background-color: #292b2c;
    text-align: center;
    padding: 50px 25px;
    max-width: 420px;
    margin: auto;
}
.register > form {
    text-align: center;
}
.register > #register-suggestion {
    padding-top: 50px;
}
#register-suggestion > #rsuggestion-redirect {
    text-decoration: none;
    color: rgb(180,180,180);
    transition: 100ms color ease-in-out;
}
#register-suggestion > #rsuggestion-redirect:hover {
    color: white;
    cursor: pointer;
}

.register > form > input {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 2rem;
    padding: 1rem;
    font-size: 1.1em;
    font-weight: 500;
    margin-bottom: 2rem;
}
.register > form > button, .register > form > .div-button-r-l {
    margin-top: 0.5em;
    border: none;
    padding: 1rem;
    font-size: 1.2em;
    border-radius: 0.2rem;
    transition: 0.4s;
    background-color: #c7c7c7;
}
.register > form > button:hover {
    color: rgb(211, 155, 52);
    background-color: white;
}
.reg-container > #message {
    margin-top: 20px;
    font-size: 1.2em;
    text-align: center;
    color: #36a936;
}